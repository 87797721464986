import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

const NotFoundPage = () => (
  <Layout>
    <SEO title="Oeps" />
    <h1>Oeps. Pagina niet gevonden</h1>
    <p>U heeft een pagina bereikt dat niet bestaat.</p>
  </Layout>
)

export default NotFoundPage
